.searchPage {
    margin-top: 100px;
}

.container {
    margin-top: 80px;
    padding-top: 30px;
}

.content {
    background-color: white;
}

.filter {
    background-color: white;
}

.filterButton {
    position: fixed;
    width: 100%;
    background-color: white;
    bottom: 0;
    box-shadow: 0 -2px 9px -1px rgba(0, 0, 0, .3);
    z-index: 1200;
}

.borderRight {
    border-right: 1px solid #ccc;
}

.applyFilters {
    background-color: #4CAF50;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
}
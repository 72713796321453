.parentDiv {
    display: flex;
    padding: 40px 0;
    padding-top: 10px;
}
/* .table{
    width: 450px
} */
.table td {
    padding: 10px 20px;
    text-align: left;
    border: 1px solid #eee;
}

.table td:nth-child(2n-1) {
    background-color: #eee;
    border: 1px solid white;
}

@media (max-width:700px) {
    .parentDiv {
        display: block;
    }
    .table {
        width: 100%;
    }
    .table td {
        width: 50%;
    }
}
.contactUs {
    margin-top: 70px;
    padding-top: 40px;
}

.contactUs h1 {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
}

.info {
    font-size: 16px;
    text-align: left;
    color: #777;
}

.info p {
    margin-bottom: 0;
}

.info hr {
    border-bottom: 2px solid #4caf50
}

.info a, .info a:hover, .info a:active {
    padding: 5px;
    color: #777;
    text-decoration: underline #4caf50;
}
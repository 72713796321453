.testimonials h1 {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: left;
}

.avatar {
    height: 50px;
    width: 50px;
    border-radius: 100px;
}

.avatar img {
    height: 60px;
    width: 60px;
    margin-right: 10px;
    border-radius: 100px;
}
.title{
    text-align: center;
    font-size: 21px;
    font-weight: 700;
    color: #4caf50;
    padding-bottom: 0px;
}

.register{
    padding-top: 5px;
}

.register span:nth-child(2){
    color: #4caf50;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
}

.register span:nth-child(2):hover{
    color: #2E7D32;
}

.inputClass{
    background-color: '#EEF5EE';
    border: 'none';
    margin-bottom: '20px';
}
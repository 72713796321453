.login {
    font-size: 14px;
    font-weight: 600;
    color: #A9A9A9;
    text-align: center;
    padding-top: 10px ;
}

.login span, .login span:hover{
    color: #2E7D32;
    cursor: pointer;    
}

.active{
    color: #FFC857;
}

.verified {
    color: #4CAF50;
}

.success{
    color:#4CAF50;
    font-weight: 600;
    padding-top: 15px;
}

.error{
    font-size: 12px;
    color: red;
}
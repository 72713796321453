.file-input {
    display: inline-block;
    text-align: left;
    background: #fff;
    padding: 16px;
    width: 450px;
    position: relative;
    border-radius: 3px;
  }
  
  .file-input > [type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }
  
  .file-input > .button {
    display: inline-block;
    cursor: pointer;
    background: #eee;
    padding: 8px 16px;
    border-radius: 2px;
    margin-right: 8px;
  }
  
  .file-input:hover > .button {
    background: dodgerblue;
    color: white;
  }
  
  .file-input > .label {
    color: #333;
    white-space: nowrap;
    opacity: 0.3;
  }
  
  .file-input.-chosen > .label {
    opacity: 1;
  }
  
  .video-grid2 {
    display: grid;
    grid-template-columns: repeat(4, 0fr);
    grid-gap: 20px;
  }
  
  .video-item {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%; /* 16:9 aspect ratio for videos */
  }
  
  .delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    z-index: 1; /* Ensure the delete button is placed above the video */
  }
  
  .uploadbtn {
    background: dodgerblue;
    color: white;
    height: 40px;
    border: none;
    cursor: pointer;
  }
  .disablebtn {
    background: rgb(172, 174, 176);
    color: white;
    height: 40px;
    border: none;
    cursor: pointer;
  }
  
  .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  }
  
  .textlabel {
    padding: 10px;
    font-size: 16px;
    max-width: 100%;
  }
  
  .textinput {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 300px;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .pdf-card {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    width: 200px;
  }

  .card-actions {
    text-align: right; 
    cursor: pointer;
  }

  .download-link {
    display: inline-block;
    padding: 5px;
    background-color: rgb(46, 21, 168);
    color: #fff;
    border: none;
    border-radius: 2px;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
  }

  .download-link:hover {
    background-color: rgb(20, 42, 167);
  }
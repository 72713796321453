.bgInit {
    background-color: rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(220, 220, 220, .2);
}

.bgReg {
    background-color: #f8f9fa;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.linkClassInit {
    color: white;
    padding: 10px;
    cursor: pointer;
    font-weight: 600;
}

.linkClass {
    display: inline-block;
    color: #2E7D32;
    padding: 10px;
    cursor: pointer;
}

.logoText {
    font-size: 12px;
    color: white;
    text-align: center;
    text-shadow: 0 0 10 rgba(0, 0, 0, 0.3);
}

.logoTextreg {
    font-size: 12px;
    color: black;
    text-align: center;
}

.logoText span {
    color: #4CAF50;
}

.anchor{
    align-items: inherit;
}

.anchor a:hover {
    color: white;
    text-decoration: none;
}


.quickBuyBtn {
    background-color: #4CAF50;
    border-radius: 20px;
    padding: 5px 20px;
    color: white;
    font-weight: 500;
    font-size: 14px;
}

.quickBuyBtn.quickBuyBtn:hover{
    background: #2E7D32;
}
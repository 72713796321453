.imgContainer {
    height: 450px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media(max-width: 576px) {
    .imgContainer {
        height: 200px;
    }
}
.landing {
    min-height: 550px;
    background-image: url(../../../Assets/Mock/carF.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.introText {
    padding-top: 120px;
    width: 30%;
    text-align: center;
    margin: auto;
    margin-right: 40px;
    color: black;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.introText h1 {
    font-size: 50px;
    font-weight: 800;
    letter-spacing: 1px;
}

.introText h1 span {
    color: #4CAF50;
}

.introText p {
    /* margin-right: 10%; */
}

.tabs {
    background-color: white;
    width: max-content;
    border-radius: 50px;
    margin: auto;
    border: 2px solid #4CAF50;
    margin-bottom: 20px;
    position: relative;
}

.tab {
    display: inline-block;
    padding: 10px 15px;
    font-size: 12px;
    border-radius: 50px;
    text-transform: uppercase;
    width: max-content;
    cursor: pointer;
}

.tabs a, .tabs a:hover, .tabs a:active {
    color: rgb(56, 56, 56);
    text-decoration: none;
    font-weight: 600;
}

.selectedTab {
    background-color: #4CAF50;
    color: white;
    /* transition: all 0.3s ease-in-out; */
}

.inputField {
    width: 30%;
    margin-left: auto;
    margin-right: 40px;
    background-color: rgba(0, 0, 0, 0.2);
    border: 3px solid #4CAF50;
    height: 100%;
}

.myInput {
    border-radius: 0;
    outline: none;
    background-color: white;
    box-shadow: none;
    border: 1px solid white;
    border-right: 1px solid #bbb;
}

.myInput:focus {
    outline: none;
    box-shadow: none;
}

.myBtn {
    /* border-left: 1px solid black; */
    /* border-right: 1px solid black; */
    border-radius: 0;
    background-color: #4CAF50;
    border: 1px solid #4CAF50;
    color: white;
}

.myBtn:focus {
    box-shadow: none;
}

.goBtn {
    width: 100%;
    border-radius: 20px;
    color: white;
    background-color: #4CAF50;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.goBtn:hover {
    background-color: #2E7D32;
    color: white;
}

.autoComplete {
    position: relative;
    display: inline-block;
}

.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
}

.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
}

.autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #ffffff;
}

@media(max-width: 768px) {
    .introText {
        text-align: center;
        width: 100%;
    }
    .inputField {
        margin: auto;
        width: 50%;
    }
}

@media (max-width:576px) {
    .inputField {
        width: 100%;
    }
}

@media (max-width:992px) {
    .goBtn {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
}
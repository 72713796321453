.hiring {
    text-align: left;
    padding-top: 50px;
    padding-bottom: 50px;
}

.hiring h1 {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 700;
}

.positions {}

.positionChip {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 4px;
    /* border: 1px solid #4CAF50; */
    margin: 10px;
    font-size: 12px;
    background-color: #4CAF50;
    background-image: linear-gradient(to right, rgb(102, 156, 112), rgb(166, 194, 116));
    color: white;
}

.tag {
    margin-top: 20px;
    text-align: right;
    /* padding-left: 50px; */
    font-size: 14px;
}

.tag a span{
    color: #4CAF50;
    text-decoration: underline;   
}
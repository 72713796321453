.title{
    color: #A9A9A9;
    font-size: 16px;
}

.title span:nth-child(2){
    color: black;
    font-weight: 700;
}

.resend{
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: left;
    font-size: 14px;
    color: #A9A9A9;
}

.resend span:nth-child(2){
    color: #4caf50;
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
}

.resend span:hover{
    color: #2E7D32;
}
.file-input {
    display: inline-block;
    text-align: left;
    background: #fff;
    padding: 16px;
    width: 450px;
    position: relative;
    border-radius: 3px;
  }
  
  .file-input > [type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }
  
  .file-input > .button {
    display: inline-block;
    cursor: pointer;
    background: #eee;
    padding: 8px 16px;
    border-radius: 2px;
    margin-right: 8px;
  }
  
  .file-input:hover > .button {
    background: dodgerblue;
    color: white;
  }
  
  .file-input > .label {
    color: #333;
    white-space: nowrap;
    opacity: .3;
  }
  
  .file-input.-chosen > .label {
    opacity: 1;
  }

  .video-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  
  .video-item {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%; /* 16:9 aspect ratio for videos */
  }
  
  .delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    z-index: 1; /* Ensure the delete button is placed above the video */
  }
  
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
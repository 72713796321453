.file{
    background-color: #EEF5EE ;
    border: 2px solid #E1E1E1 ;
    width: 100%;
    padding: 10px 50px;
    text-align: left;
    font-size: 14px;
    color: #C4C4C4;
}

.icon{
    position: absolute;
    right: 52px;
}
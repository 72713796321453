.footer {
    background-color: #262626;
    z-index: 100;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 20px;
    color: white;
}

.footer a, .footer a:hover, .footer a:active{
    color: white;
    text-decoration: none;
}

.footer h3 {
    margin-bottom: 2px;
}

.social div {
    display: inline-block;
    padding: 5px;
}

.mydiv {
    border-radius: 24px;
    height: 3px;
    width: 60px;
    background-color: #99ca3c;
    margin-bottom: 10px
}

.links a:active, .links a, .links a:hover {
    color: white;
    text-decoration: none;
}

.social a:active, .social a, .social a:hover {
    color: white;
    text-decoration: none;
}
.title {
  font-size: 14px;
  color: grey;
  text-transform: uppercase;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid#ccc;
  cursor: pointer;
}

.search input:focus {
  outline: none;
  box-shadow: none;
  border: 2px solid #4CAF50;
  border-radius: 4px;
}

/* The containers */

.containers {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.containers input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  margin-bottom: -5px;
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border: 1px solid #4CAF50;
}

/* On mouse-over, add a grey background color */

.containers:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.containers input:checked~.checkmark {
  background-color: #4CAF50;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.containers input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.containers .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: 1px solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
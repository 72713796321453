.myCard {
    cursor: pointer;
}

.myCard a {
    color: black;
}

.myCard a:hover, .myCard:active {
    color: black;
    text-decoration: none;
}

.myCard:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.title {
    font-size: 16px;
}

.price {
    font-size: 18px;
    font-weight: 600;
    text-align: right;
}

.stats {
    color: #777;
    font-size: 12px;
    padding-top: 7px;
}

.borderRight {
    border-right: 1px solid #ccc
}

.tag {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
}

.label {
    color: white;
    font-size: 14px;
    padding: 5px 10px;
    background-color: #4caf50;
}

.border {
    display: inline-block;
    height: 0;
    width: 0;
    border-top: 15px solid #4caf50;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #4caf50;
    border-right: 15px solid transparent;
}

.ratingLabel {
    font-size: 12px;
    vertical-align: top;
    padding-left: 2px;
    color: #777;
}
.image_logo{
    float: left;
    margin: 3%;
}
.cars_label{
    text-align: left;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    opacity: 1;
}
.cars_static_label{
    text-align: left;
    font: normal normal 600 12px/16px Segoe UI;
    letter-spacing: 0px;
    color: #A9A9A9;
    opacity: 1;
}
.cars_main_label_tag{
    text-align:center;
}
.cars_head_label{
    text-align: left;
    font: normal normal 600 20px/27px Segoe UI;
}
.price_tag{
    padding-right: 10px;
    text-align: left;
    font: normal normal bold 35px/47px Segoe UI;
    letter-spacing: 0px;
    color: #4CAF50;
    opacity: 1;
    text-align: right;
}

@media (max-width: 600px){
    .price_tag{
        font-size: 20px;
    }
}
.text_left{
    text-align: left;
}
.quick_buy{
    background: #4CAF50 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #4CAF50;
    border-radius: 28px;
    opacity: 1;
    padding: 5px 50px;
}
.show_all_feature{
    text-align: left;
    font: normal normal 600 12px/16px Segoe UI;
    letter-spacing: 0px;
    color: #4CAF50 !important;
    opacity: 1;
}
th td{
    width:25%;
}
.card_img{
    width: 100%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
}
.table_layout{
    table-layout: fixed;
}
@media (max-width:600px){
    .cars_img_tag{
        display: none !important;
    }
    .table_layout{
        table-layout:unset;
    }
    .show_cars{
        display: show !important;
    }
}
@media (min-width:601px) and (max-width:960px){
    .cars_img_tag{
        display: show !important;
    }
    .show_cars{
        display: none !important;
    }
}
.btn{
    height: max-content;
    padding: 2px;
    border: 2px solid #4caf50;
    color: white;
}

.btn p{
    padding: 5px 20px;
    background-color: #4caf50;
    margin-bottom: 0;
}
.summary {
    min-height: 1000px;
    margin-top: 80px;
    /* background-color: #eee; */
}

.title {
    text-transform: capitalize;
    font-size: 25px;
    font-weight: 600;
    width: 90%;
}

.price {
    font-size: 21px;
    color: #2E7D32;
}

.price span {
    font-size: 12px;
    text-transform: uppercase;
    color: grey
}

.triangle {
    height: 0;
    width: 0;
    border-top: 45px solid #4CAF50;
    border-left: 45px solid transparent;
    border-bottom: 45px solid transparent;
    border-right: 45px solid #4CAF50;
    position: absolute;
    top: 0;
    right: 0;
}

.stats {
    color: #777;
    font-size: 12px;
    padding-top: 7px;
}

.borderRight {
    border-right: 1px solid #ccc
}

.description {
    padding: 10px
}

.whiteCont {
    background-color: white;
    /* width: max-content */
    /* min-width: max-content; */
}

@media (max-width: 1250px) {
    .whiteCont {
        /* min-width: max-content; */
        width: 100%;
    }
}

.success {
    padding: 30px;
    background-color: #4caf50;
    text-align: center;
    font-size: 12px;
    width: max-content;
    margin: auto;
    color: white;
    border-radius: 4px;
    margin-top: 10px;
}

.backButton {
    color: grey;
    background-color: white;
    width: max-content;
    padding: 5px !important;
    border-radius: 4px;
    margin-left: 33px;
    margin-bottom: 10px;
    cursor: pointer;
}
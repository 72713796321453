.quickBuy{
    min-height: auto;
    padding-top: 70px;
}

.card{
    width: 230px; 
    height: auto;
}

.card .imgContainer {
    height: 220px;
}
.card img
{   
    padding: 35px;
    width: 100%;
}

.card h3{
    color: #4CAF50;
    font-size: 18px;
    font-weight: 700;
}

.card p {
    font-size: 12px;
}

.banner{
    padding-top: 51px;
    min-height: 300px;
    background-image: url('../../Assets/QuickBuy/Path\ 17.svg');
    
    background-position: top;
}

.btn {
    background-color: #4CAF50;
    border-radius: 50px;
    width: max-content;
    margin: auto;
    padding: 10px 100px;
    color: white;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
}

.btn:hover{
    background: #2E7D32;
    text-decoration: none;
}

.register {
    font-size: 14px;
    font-weight: 600;
    color: #A9A9A9;
    padding-top: 4px ;
}

.register a span, .register a span:hover{
    color: #2E7D32;
    cursor: pointer;
}

.card2{
    width: 190px;
    background-color: white;
    box-shadow: 0px 1px 10px #00000029;
    margin: 20px;
    border-radius: 10px;
    cursor: pointer;    
}

.price{
    color: #4CAF50;
    font-size: 22px;
    font-weight: 700;
}

.title{
    color: black;
    font-weight: bold;
    font-size: 14px;
}

.imgContainer{

}

.imgContainer img{
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

}

.imgContainer2 img{
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 130px;
}
.whyChooseUs {
    background-color: #eee;
    padding-top: 60px;
    padding-bottom: 60px;
}

.contactButton {
    color: #4CAF50;
    border: solid 2px #4CAF50;
    cursor: pointer;
    padding: 8px 30px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 600;
    transition: .5s;
    border-radius: 50px!important;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.contactButton:hover {
    background-color: #4CAF50;
    color: white;
    text-decoration: none
}

.icon {
    padding-left: 15px;
    padding-right: 15px;
}
.success {
    padding: 30px;
    background-color: #4caf50;
    text-align: center;
    font-size: 12px;
    width: max-content;
    margin: auto;
    color: white;
    border-radius: 4px;
    margin-top: 10px;
}

.heading {
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 0;
}

.line {
    width: 100px;
    height: 5px;
    background-color: #4caf50;
    margin-left: 39%;
    margin: auto;
}

@media (max-width: 576px) {
    .heading {
        font-size: 25px
    }
    .line {
        margin-left: none;
    }
}

.myHr {
    margin-bottom: 0;
}

.seperator {
    padding: 5px 10px;
    background-color: white;
    width: max-content;
    margin: auto;
    margin-top: -20px;
}

.text {
    text-align: left;
    padding-left: 23%;
    font-size: 20px;
    font-weight: 600;
}

.text a, .text a:hover, .text a:active {
    color: #4caf50;
    text-decoration: none;
}

@media (max-width: 576px) {
    .text {
        font-size: 14px;
        padding-left: 15px;
    }
}